<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="活动"
            class="search-input"
            v-model="searchObj.bargainName"
          ></el-input>
          <el-select
            placeholder="活动状态"
            class="search-input ml-15"
            v-model="searchObj.bargainStatus"
          >
            <el-option
              v-for="(item, index) in activity_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-date-picker
            class="ml-15"
            v-model="date"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link
                :to="{ name: 'Ripoff_new', params: { addFlag: true } }"
              >
                <el-button type="primary">添加活动</el-button>
              </router-link>
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <el-table-column
              label="活动名称"
              prop="bargainName"
              width="260px"
            ></el-table-column>
            <el-table-column label="商品图片" width="150px">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.pictureUrl)"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="商品原价" prop="tagPrice"></el-table-column>
            <el-table-column label="底价" prop="lowerPrice"></el-table-column>
            <el-table-column label="销量" prop="salesVolume"></el-table-column>
            <el-table-column label="库存" prop="stock"> </el-table-column>
            <el-table-column label="活动时间" width="320px">
              <template slot-scope="scope"
                >{{ scope.row.startTime }} ~ {{ scope.row.endTime }}</template
              >
            </el-table-column>
            <el-table-column label="活动状态" prop="bargainStatus">
            </el-table-column>
            <el-table-column label="操作" prop="operations" width="210">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{
                      name: 'Ripoff_new',
                      params: { addFlag: false, id: scope.row.id },
                    }"
                  >
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit-outline"></i>
                      {{
                        scope.row.bargainStatus == "已结束" ||
                        scope.row.bargainStatus == "进行中"
                          ? "查看"
                          : "编辑"
                      }}
                    </el-link>
                  </router-link>
                  <router-link
                    v-if="scope.row.bargainStatus !== '未开始'"
                    :to="{
                      name: 'Ripoff_records',
                      params: { id: scope.row.id },
                    }"
                  >
                    <el-link type="primary" :underline="false" class="ml-10">
                      <i class="el-icon-document"></i>
                      砍价记录
                    </el-link>
                  </router-link>
                  <span
                    v-if="
                      scope.row.bargainStatus == '进行中'
                    "
                    class="ml-10 tip-text-delete"
                    @click="disableRip(scope.row)"
                  >
                    <i class="el-icon-setting"></i>
                   终止
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { disableRipoff, getRipoffList } from "@/api/maketing/ripoff";
export default {
  name: "ripoff_list",
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        bargainName: "",
        bargainStatus: "",
      },
      date: [],
      //活动状态
      activity_status: [
        {
          label: "已结束",
          value: 1,
        },
        {
          label: "进行中",
          value: 2,
        },
        {
          label: "未开始",
          value: 3,
        },
      ],
      // 优惠券表格数据内容
      tableData: [],
      addFlag: true,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {
    // console.log("---");
    this.initData();
  },
  methods: {
    //列表查询
    initData() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchObj,
      };
      data.startTime = this.date[0];
      data.endTime = this.date[1];
      // console.log(data);
      getRipoffList(data).then((res) => {
   // console.log(res.data.body)
        this.total = res.data.body.total;
        this.tableData = res.data.body.list;
      });
    },
    // 搜索事件
    search() {
      this.initData();
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        bargainName: "",
        bargainStatus: null,
      };
      this.date = [];
      this.initData();
    },
    //点击终止
    disableRip(row) {
      // console.log(row);
      this.$confirm(
        "此操作将终止该活动, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          disableRipoff(row.id,0).then(() => {
            this.$message({
              type: "success",
              message: "终止成功!",
            });
            this.initData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消终止",
          });
        });
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
